<template>
  <Component
    :is="url ? NuxtLink : 'div'"
    :to="url"
    class="relative"
  >
    <div
      ref="mediaItem"
      :class="`group will-change-transform transition-transform ease-[cubic-bezier(0.64,0.57,0.67,1.8)] duration-300 ${url ? 'hover:scale-x-95' : ''}`"
    >
      <div
        v-if="image"
        :class="`relative overflow-hidden rounded-3xl ${ratio ? ratio : 'aspect-[10/7] md:aspect-[12/13]'}`"
      >
        <img
          :src="image.url"
          :srcSet="imageSrcset?.trim()"
          :alt="image.alt"
          :class="`h-full w-full object-cover will-change-transform transition-transform ease-[cubic-bezier(0.64,0.57,0.67,1.8)] duration-300 ${url ? 'group-hover:scale-x-105' : ''}`"
          :sizes="imageSizes?.trim()"
          :width="image.width"
          :height="image.height"
          :loading="disableLazyLoading ? undefined : 'lazy'"
          draggable="false"
        >

        <div
          v-if="url"
          class="absolute flex items-center justify-center -translate-x-1/2 -translate-y-1/2 h-36 w-36 text-white bg-orange rounded-full will-change-transform"
          data-gsap-target="bubble"
        >
          <Icon icon="svgo-plus" class="h-12 w-12" />
        </div>

        <div v-if="logo">
          <img
            :src="logo.xs.url"
            :alt="logo.xs.alt"
            class="absolute right-8 bottom-8 h-14 w-14 object-contain"
            :width="logo.xs.width"
            :height="logo.xs.height"
            loading="lazy"
            draggable="false"
          >
        </div>

        <div
          v-if="title && !titleBelow"
          class="absolute left-0 bottom-0 p-6 pt-24 w-full text-[0.875rem] text-white font-medium leading-tight bg-gradient-to-t from-black/60"
        >
          <span v-if="company" class="font-serif text-base leading-none">
            {{ company }} &bull;
          </span>

          {{ title }}
        </div>
      </div>

      <div :class="`relative mt-4 ${tags?.length ? 'min-h-12' : ''}`">
        <div
          v-if="title && titleBelow"
          class="-mt-1 text-xl font-serif font-bold text-purple-dark transition-opacity duration-300 lg:text-2xl xl:text-3xl"
          :class="{
            'group-hover:opacity-0': tags?.length
          }"
        >
          {{ title }}
        </div>

        <div v-if="date" class="lg:text-lg">
          {{ formatDate(date) }}
        </div>

        <div
          v-if="tags?.length"
          class="flex items-center flex-wrap gap-2"
        >
          <div
            v-for="tag in tags.slice(0, 2)"
            :key="tag.id"
            class="btn px-3 h-8 bg-white text-purple-dark text-xs md:px-4 md:h-12 md:text-base"
          >
            {{ tag.title }}
          </div>

          <div
            v-if="tags.length > 2"
            class="flex items-center justify-center size-8 text-xs text-white font-medium border-2 border-white rounded-full md:size-12 md:text-base"
          >
            +{{ tags.length - 2 }}
          </div>
        </div>
      </div>
    </div>
  </Component>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components';
import type { Image } from '~/statamic/types/image';

defineProps<{
  url?: string;
  image?: Image;
  title?: string;
  company?: string;
  date?: string;
  tags?: {
    id: string;
    title: string
  }[];
  logo?: {
    xs: Image;
  };
  ratio?: string;
  imageSrcset?: string;
  imageSizes?: string;
  titleBelow?: boolean;
  disableLazyLoading?: boolean;
}>();

let ctx: gsap.Context | null;
let moveBubble: Function;
const mediaItem = ref();
const {
  elementX,
  elementY,
  isOutside,
  stop,
} = useMouseInElement(mediaItem);

const mouseYInElement = ref(elementY.value);
const mouseXInElement = ref(elementY.value);

watchThrottled([elementX, elementY], () => {
  mouseYInElement.value = elementY.value;
  mouseXInElement.value = elementX.value;

  moveBubble();
}, { throttle: 100 });

onMounted(() => {
  ctx = useGsap.context((self) => {
    if (typeof self.selector !== 'function') return;

    const bubble = self.selector('[data-gsap-target="bubble"]');

    moveBubble = () => {
      if (bubble?.length) {
        useGsap.to(bubble, {
          top: mouseYInElement.value,
          left: mouseXInElement.value,
          scale: isOutside.value ? 0.5 : 1,
          duration: 0.5,
          autoAlpha: isOutside.value ? 0 : 1,
        });
      }
    };
  }, mediaItem.value);
});

onUnmounted(() => {
  ctx?.revert();
  stop();
});
</script>
